var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
export class AbstractClient {
    get(url) {
        return __awaiter(this, void 0, void 0, function* () {
            return fetch(url, {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' }
            })
                .then((response) => __awaiter(this, void 0, void 0, function* () {
                if (response.status >= 400)
                    throw yield this.getJsonResult(response);
                return this.getJsonResult(response);
            }))
                .catch(err => {
                throw err;
            });
        });
    }
    post() {
        return __awaiter(this, arguments, void 0, function* (url = '', payload = {}) {
            return fetch(url, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(payload)
            })
                .then((response) => __awaiter(this, void 0, void 0, function* () {
                if (response.status >= 400)
                    throw yield this.getJsonResult(response);
                return this.getJsonResult(response);
            }))
                .catch(err => {
                throw err;
            });
        });
    }
    getJsonResult(response) {
        return __awaiter(this, void 0, void 0, function* () {
            return response.text().then((response_text) => {
                const response_json = response_text === "" ? {} : JSON.parse(response_text);
                return response_json;
            });
        });
    }
}
